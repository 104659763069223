<template>
  <div class="bg">
    <div class="imageblock">
      <img class="img404" alt="" src="/img/logo.svg" style="max-width: 100vw" />
      <h2 class="h2404">Ошибка</h2>
      <h1 class="h1404">404</h1>
      <h3 class="h3404">Страница не найдена</h3>
    </div>
    <p class="p404">Попробуйте сначала или свяжитесь с нами по телефону</p>
    <h4 class="h4404">+7 (495) 152-26-26</h4>
  </div>
</template>

<script>
export default {
  name: 'notFound',
}
</script>

<style>
html {
  overflow-x: hidden !important;
}
.bg {
  width: 100vw;
  height: 100vh;
  background-color: #f5f7f8;
  display: flex;
  flex-direction: column;
  overflow-x: hidden !important;
}
.imageblock {
  background-position: center;
  display: flex;
  flex-direction: column;
  margin-top: 10rem;
  align-self: center;
  position: relative;
}
.img404 {
  position: absolute;
  z-index: -1;
  top: 10%;
  max-width: 100vw;
}
.h1404 {
  z-index: 1;
  background: transparent;
  font-family: 'Sansation',Arial,sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 200px;
  line-height: 100%;
  text-transform: uppercase;
  color: #000000;
}
.h2404 {
  align-self: center;
  font-family: 'Sansation',Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
}
.h3404 {
  align-self: center;
  position: absolute;
  bottom: 1rem;
  font-family: 'Sansation',Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #000000;
}
.p404 {
  font-family: 'Sansation',Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #000000;
}
.h4404 {
  font-family: 'Sansation',Arial,sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 2rem;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #000000;
}
@media screen and (max-width: 500px) {
  .h1404 {
    font-size: 120px;
  }
  .p404 {
    font-size: 12px;
  }
}
</style>
