<template>
  <span class='title-name'>{{ text }}</span>
</template>
<script>
export default {
  name: 'HeaderTitleNew',
  props: {
    messages: Array
  },

  data() {
    return {
      text: "",
      index: 0,
      messageIndex: 0,
      isDeleting: false,
      timer: null
    };
  },

  methods: {
    typeWriter() {
      const currentMessage = this.messages[this.messageIndex];
      this.text += currentMessage.charAt(this.index);
      this.index++;

      if (this.index === currentMessage.length) {
        this.isDeleting = true;
        clearInterval(this.timer);
        setTimeout(() => {
          this.timer = setInterval(this.deleteText, 50);
        }, 3000);
      }
    },

    deleteText() {
      const currentMessage = this.messages[this.messageIndex];
      this.text = currentMessage.substring(0, this.text.length - 1);

      if (this.text.length === 0) {
        clearInterval(this.timer);

        if (this.isDeleting) {
          this.messageIndex = (this.messageIndex + 1) % this.messages.length;
          this.isDeleting = false;
        }

        setTimeout(() => {
          this.timer = setInterval(this.typeWriter, 50);
          this.index = 0;
        }, 1000);
      }
    }
  },

  mounted() {
    this.timer = setInterval(this.typeWriter, 100);
  }
};
</script>

<style scoped>
.title-name {
  color:#fff;
  text-transform: uppercase;
}
.title-name {
  display: inline-block;
  max-width: 840px;
  white-space: pre-line;
}
</style>
