<template>
  <div class='counter-container'>
    <span v-if=minus v-observe-visibility='onVisibilityChanged'>
      -{{ formattedCounter }}
    </span>
    <span v-else  v-observe-visibility='onVisibilityChanged'>
      {{ formattedCounter }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    targetValue: {
      type: String,
      required: true,
    },
    minus: Boolean
  },
  data() {
    return {
      counter: 0,
      isVisible: false,
    };
  },
  computed: {
    formattedCounter() {
      return this.counter.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    },
  },
  methods: {
    onVisibilityChanged(isVisible) {

      if (isVisible && !this.isVisible) {
        this.isVisible = true;
        this.numberCounter();
      }
    },
    numberCounter() {
      const target = parseFloat(this.targetValue.replace(/,/g, ''));

      const duration = 2000; // Задайте продолжительность анимации в миллисекундах
      const increment = target / (duration / 50);

      let currentCounter = 0;
      const interval = setInterval(() => {
        currentCounter += increment;
        if (currentCounter >= target) {
          this.counter = target;
          clearInterval(interval);
        } else {
          this.counter = Math.round(currentCounter * 100) / 100;
        }
      }, 50);
    },
  },
};
</script>
<style>
@media (max-width: 700px) {
  .big.main-sum-description {
    font-size: 28px;
  }

}
</style>

<style scoped>


</style>
