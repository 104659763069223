<template>
  <div class="video-background">
    <video autoplay muted loop>
      <!-- <source src="../assets/video/video-background.mp4" type="video/mp4"> -->
    </video>
  </div>
</template>
<script>
export default {
  name: 'VideoBackground',
}
</script>

<style>
.video-background {
  position: absolute;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 1);
}
/*.video-background::after {*/
/*  background: url('/public/img/svg.png') 0 0 no-repeat;*/
/*  content: '';*/
/*  position: absolute;*/
/*  width: 100%;*/
/*  height: 230px;*/
/*  z-index:2;*/
/*  bottom: -1px;*/
/*  background-size: contain;*/
/*}*/
.video-background video {
  opacity: 0.2;
  position: absolute;
  left: 50%;
  top: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  z-index: 0;
}

.content {
  position: relative;
  z-index: 1;
  text-align: center;
  color: #fff;
  padding: 50px;
}
</style>
