<template>
  <section class="sec8">
    <div class="container">
      <div class="row ">
        <h2 class="t1">Схема нашей работы</h2>
        <div class="block about-work-wrapper">
          <div class="item dflex" v-for='step in this.steps' :key='step.id'>
            <div class="it1 dflex fcolumn">
              <img v-if='step.id <6' class='arrow' src='/img/about/arrow.svg'>
              <span class='number'><b>{{step.id}}</b></span>
              <img :src=step.image class='icon' width='49' height='49' alt="">

            </div>
            <div class="it2">
<!--              <div class='itt1'>{{step.title}}</div>-->
              <div class="itt2">
                {{step.description}}
              </div>
            </div>
          </div>
        </div>
    </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'aboutWork',
  data() {
    return {
      steps: [
        {
          id: 1,
          image: 'img/about/1.svg',
          title: 'Отправьте заявку',
          description: 'Позвонить нам по номеру +7 (495) 154-76-71 или оставить заявку'
        },
        {
          id: 2,
          title: 'Делаем замер',
          image: 'img/about/2.svg',
          description: 'Бесплатный выезд замерщика на объект'
        },
        {
          id: 3,
          title: 'Расчёт сметы',
          image: 'img/about/3.svg',
          description: 'Расчёт сметы на объекте за двадцать минут'
        },
        {
          id: 4,
          title: 'Подписываем договор',
          image: 'img/about/4.svg',
          description: 'Подписание договора на объекте или в офисе компании'
        },
        {
          id: 5,
          title: 'Ремонт',
          image: 'img/about/5.svg',
          description: 'Выполнение ремонтных работ в соответствии с графиком'
        },
        {
          id: 6,
          title: 'Сдача объекта',
          image: 'img/about/6.svg',
          description: 'Новоселье :)'
        },
      ]
    }
  }



};
</script>
<style scoped>
.icon {
  position: relative;
  bottom: 22px;
  width: 60px;
  height: 60px;
}
.number {
  font-size: 24px ;
  position: relative;
  bottom: 22px;
}
.arrow {
  position: absolute;
  right: 0;
}
.sec8 {
  background-color: #fff;
  padding: 87px 20px 64px 20px;
  width: 100%;
  font-family: 'Sansation',Arial,sans-serif;
}
.sec8 .t1 {
  color: #000;
  font-size: 48px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -2.4px;
  margin-bottom: 68px;


}
.dflex {
  display: flex;
}
.fcolumn {
  flex-direction: column;
}
.container {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}
.sec8 .block {
  flex-wrap: wrap;
}
.sec8 .block .item {

  margin-bottom: 82px;
  position: relative;
}
.sec8 .block .item .it1 {
  text-align: center;
  color: #000;
  font-size: 48px;
  font-weight: 400;
  line-height: 60px;
  letter-spacing: -1.2px;

}
.fcolumn {
  flex-direction: column;
}
.sec8 .block .item .it2 .itt1 {
  margin-top: 5px;
  color: #000;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.6px;
  margin-bottom: 20px;
}
.sec8 .block .item .it2 .itt2 {
  color: #000;

  line-height: 24px;
  letter-spacing: 0.35px;
  font-weight: 500;
  font-size: 18px;
}
.sec8 .block .item .it2 {
  margin-left: 30px;
  width: 100%;
  position: relative;
  top: 34px;

}
.about-work-wrapper {
  display: grid;
  grid-template-columns: repeat(3,1fr);
  width: 100%;
}
.sec8 .t1 {
  margin-left: 25px;
}


@media(max-width: 944px){
  .about-work-wrapper {
    grid-template-columns: repeat(2,1fr);
    width: 100%;
    padding: 0 10px;
  }
  .sec8 .t1 {
    margin-left: 25px;
  }
  .arrow {
    width: 80%;
  }
}
@media(max-width: 650px){
  .about-work-wrapper {
    grid-template-columns: repeat(1,1fr);
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .sec8 .block .item {
    width: 300px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  .sec8 .t1 {
    font-size: 30px;
    margin-left: 25px;
  }
}
</style>
