<template>
  <div class="container">
    <h2 class="mb-50">
      Хотим вас уже сейчас познакомить со всеми, кто будет обслуживать ваш проект
    </h2>


        <div class="worker_card_wrapper" >
          <WorkerCard
            :name="measurer.name"
            desc="Ваш персональный менеджер объекта, будет вместе с вами вести ваш объект"

            :photo="measurer.photo"
          />
          <WorkerCard
            name="Степан Степанов"
            desc="Предварительный прораб на вашем объекте.
            За плечами более 300 успешных проектов."

            photo="../../../img/foreman.png"
          />
        </div>

    <div class="img_wrapper">
      <img src="/img/info.svg" alt="info" />
    </div>
    <span>Мастера на проект будут назначены сразу после подписания договора</span>
  </div>
</template>

<script>
import WorkerCard from '@/components/Workers/WorkerCard.vue'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Workers',
  components: { WorkerCard },
  props: {
    measurer: Object,
    prorab: Object,
  },
}
</script>
<style>
.worker_card_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10%;
}
@media (min-width: 1200px) {
  .worker_card_wrapper {

    gap: 10%;
  }
}
.worker_card_wrapper {

}
</style>
<style scoped>
.mb-25 {
  margin-bottom: 25px;
}
.mb-50 {
  margin-bottom: 50px;
}
.img_wrapper {
  display: flex;
  margin-bottom: 15px;
}
.img_wrapper img {
  max-width: 35px;
}

@media screen and (max-width: 900px) {
  .worker_card_wrapper {
    gap: 30px;
  }
  .worker_card_wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .container {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  h2 {
    margin: 0 10px;
    font-size: 20px;
  }
}
@media screen and (min-width: 900px) {
  .container {
    padding-top: 96px;
    padding-bottom: 68px;
  }
  .pd-20 {
    padding: 0 20px;
  }
  h2 {
    font-size: 22px;
  }
}

h2 {
  max-width: 967px;
  font-family: 'Sansation',Arial,sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
}
span {
  max-width: 309px;
  font-family: 'Sansation',Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: #000000;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #f5f7f8;
}
.worker_card_wrapper {
  display: flex;
  align-content: space-around;
}
span {
  max-width: 640px;
  font-family: 'Sansation',Arial,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #000000;
  padding: 4px;
}
</style>
