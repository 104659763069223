<template>
  <div class="main_window">
    <div class="cloud" data-type="white_4" style="top: 138px;" data-speed="1"></div>
    <div class="cloud" data-type="white_2" style="top: 100px;" data-speed="2"></div>
    <div class="cloud" data-type="white_1" style="top: 481px;" data-speed="3"></div>
    <div class="cloud" data-type="white_1" style="top: 500px;" data-speed="4"></div>
    <div class="cloud" data-type="white_5" style="top: 391px;" data-speed="5"></div>
    <div class="cloud" data-type="white_4" style="top: 410px;" data-speed="6"></div>
    <div class="cloud" data-type="white_6" style="top: 494px;" data-speed="7"></div>
    <div class="cloud" data-type="white_6" style="top: 197px;" data-speed="8"></div>
    <div class="cloud" data-type="white_1" style="top: 400px;" data-speed="9"></div>
    <div class="cloud" data-type="white_2" style="top: 108px;" data-speed="10"></div>
    <div class="cloud" data-type="white_5" style="top: 133px;" data-speed="11"></div>
    <div class="cloud" data-type="white_5" style="top: 126px;" data-speed="12"></div>
    <div class="cloud" data-type="white_5" style="top: 150px;" data-speed="13"></div>
    <div class="cloud" data-type="white_5" style="top: 515px;" data-speed="14"></div>
    <div class="cloud" data-type="white_6" style="top: 410px;" data-speed="15"></div>


  </div>

</template>

<script>
export default {
  name: 'Sky',
}
</script>

<style scoped>
.main_window{
  overflow: hidden;
  display: block;
  padding: 0;
  margin: 0 auto;
  width: 100vw;
  height: 624px;
  position: absolute;
  z-index: 0;
}
div{
  margin: 0px;
  padding: 0px;
}

.cloud{
  width: 200px;
  height: 50px;
  background-color: #fff;
  border-radius: 30px;
  position: absolute;
  top: 100px;
}

[data-type=white_1]:before{
  content: "";
  display: block;
  width: 50px;
  height: 50px;
  top: -20px;
  left: 20px;
  background-color: #fff;
  border-radius: 30px;
  position: absolute;
  box-shadow: 80px 0 0 10px #fff, 40px 0 0 20px #fff, 110px 0 0 -5px #fff;
}

[data-type=white_2]:before{
  content: "";
  display: block;
  width: 50px;
  height: 50px;
  top: -20px;
  left: 25px;
  background-color: #fff;
  border-radius: 30px;
  position: absolute;
  box-shadow: 40px -10px 0 20px #fff, 80px -10px 0 10px #fff, 115px -5px 0 -10px #fff;
}

[data-type=white_3]:before{
  content: "";
  display: block;
  width: 50px;
  height: 50px;
  top: -20px;
  left: 25px;
  background-color: #fff;
  border-radius: 30px;
  position: absolute;
  box-shadow: 40px -10px 0 15px #fff, 90px 0px 0 15px #fff;
}

[data-type=white_4]:before{
  content: "";
  display: block;
  width: 50px;
  height: 50px;
  top: -20px;
  left: 20px;
  background-color: #fff;
  border-radius: 30px;
  position: absolute;
  box-shadow: 80px 0 0 10px #fff, 40px 0 0 20px #fff, 110px 0 0 -5px #fff,
  80px 25px 0 10px #fff, 40px 25px 0 20px #fff, 0 40px 0 -5px #fff;
}

[data-type=white_5]:before{
  content: "";
  display: block;
  width: 50px;
  height: 50px;
  top: -20px;
  left: 25px;
  background-color: #fff;
  border-radius: 30px;
  position: absolute;
  box-shadow: 40px -10px 0 20px #fff, 80px -10px 0 10px #fff, 115px -5px 0 -10px #fff,
  20px 30px 0 20px #fff, 70px 30px 0 10px #fff, 105px 40px 0 -10px #fff;
}

[data-type=white_6]:before{
  content: "";
  display: block;
  width: 50px;
  height: 50px;
  top: -20px;
  left: 25px;
  background-color: #fff;
  border-radius: 30px;
  position: absolute;
  box-shadow: 40px -10px 0 15px #fff, 90px 0 0 15px #fff,
  20px 30px 0 15px #fff, 80px 40px 0 15px #fff;
}

@-webkit-keyframes linemove
{
  from {left:-200px;}
  to {left:100vw}
}
@-moz-keyframes linemove
{
  from {left:-200px;}
  to {left:100vw}
}
@-ms-keyframes linemove
{
  from {left:-200px;}
  to {left:100vw}
}
@-o-keyframes linemove
{
  from {left:-200px;}
  to {left:100vw}
}
@keyframes linemove
{
  from {left:-200px;}
  to {left:100vw}
}
[data-speed="1"]{
  animation: linemove 36.01936s;
  animation-iteration-count: infinite;
  -webkit-animation: linemove 36.01936s;

}
[data-speed="2"]{
  animation: linemove 38.67113s;
  -webkit-animation: linemove 38.67113s;

}
[data-speed="3"]{
  animation: linemove 46.99853s;
  -webkit-animation: linemove 46.99853s;

}
[data-speed="4"]{
  animation: linemove 32.6682s;
  -webkit-animation: linemove 32.6682s;

}
[data-speed="5"]{
  animation: linemove 31.20363s;
  -webkit-animation: linemove 31.20363s;

}
[data-speed="6"]{
  animation: linemove 36.46404s;
  -webkit-animation: linemove 36.46404s;

}
[data-speed="7"]{
  animation: linemove 55.58272s;
  -webkit-animation: linemove 55.58272s;

}
[data-speed="8"]{
  animation: linemove 41.02697s;
  -webkit-animation: linemove 41.02697s;

}
[data-speed="9"]{
  animation: linemove 33.79332s;
  -webkit-animation: linemove 33.79332s;

}
[data-speed="10"]{
  animation: linemove 45.69691s;
  -webkit-animation: linemove 45.69691s;

}
[data-speed="11"]{
  animation: linemove 46.87823s;
  -webkit-animation: linemove 46.87823s;

}
[data-speed="12"]{
  animation: linemove 30.158s;
  -webkit-animation: linemove 30.158s;

}
[data-speed="13"]{
  animation: linemove 27.34752s;
  -webkit-animation: linemove 27.34752s;

}
[data-speed="14"]{
  animation: linemove 32.19251s;
  -webkit-animation: linemove 32.19251s;

}
[data-speed="15"]{
  animation: linemove 35.91683s;
  -webkit-animation: linemove 35.91683s;

}

.cloud{
  -webkit-animation-timing-function:linear;
  -webkit-animation-iteration-count:infinite;
  animation-timing-function:linear;
  animation-iteration-count:infinite;
  -webkit-animation-delay: -50s;
}
</style>
