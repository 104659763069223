<template>
  <vs-table style="max-width: 1160px" :data="sums">
    <template #notFound>
      <p></p>
    </template>
    <!-- <template #header>
        <vs-input v-model="search" border placeholder="Поиск.." />
      </template> -->
    <template #thead>
      <vs-tr>
        <vs-th> Область работ </vs-th>
        <vs-th> Группа </vs-th>
        <vs-th v-if="money"> Cумма без скидки </vs-th>
        <vs-th> Итоговая сумма </vs-th>
      </vs-tr>
    </template>
    <template #tbody>
      <template v-for="(values, keys) in sums" >
        <vs-tr v-for="(el, i, a) in values" :key="el[a]">
          <template v-if="i!=='sum' && i!=='sumsale'">
            <vs-td v-if="a === 0 " :rowspan="Object.keys(values).length">
              {{ keys }}
            </vs-td>
            <vs-td>
              {{ i }}
            </vs-td>
            <vs-td v-if="money">
              {{ values[i].sum }}
            </vs-td>
            <vs-td>
              {{ values[i].sumsale }}
            </vs-td>
          </template>
        </vs-tr>
        <vs-tr hoverFlat="false" style="background-color: #f4f7f8" >
          <vs-td style="text-align: center" colspan="2">Итог</vs-td>
          <vs-td v-if="money">{{ perRoom[keys].sum }}</vs-td>
          <vs-td>{{ salePerRoom[keys].sum }}</vs-td>
        </vs-tr>
      </template>
    </template>
    <template #footer>
      <!--<vs-pagination v-model="page" :length="$vs.getLength($vs.getSearch(data, search), max)" /> -->
    </template>
  </vs-table>
</template>
<script>
export default {
  props: {
    sums: Object,
    perRoom: Object,
    salePerRoom: Object,
    money: Number,
  },
  data: () => ({
    search: "",
    page: 1,
    max: 10,
    data: [],
  }),
  created() {
    // Object.keys(this.sums).forEach((key) => {
    //   this.data.push({ area: key, sum: this.sums[key].sum, sumsale: this.sums[key].sumsale });
    // });
    // console.log(this.money);
    // eslint-disable-next-line no-console
  },
};
</script>

<style scoped>
</style>
