<template>
  <div class="container">
    <img src='../assets/background-footer.webp' class='footer-background' alt='Квартира.'/>
    <vs-row style="max-width: 1350px">
      <vs-col :lg="2" :sm="6" :xs="12" class='info-logo' >

        <div class="logo">

          <img src="/img/logo.svg" class='logo-image' alt="logo" />
        </div>
      </vs-col>
      <vs-col :lg="3" :sm="6" :xs="12">
        <div class="info ">
          <div class="card">
            <span class="title">Полное наименование юр. лица: </span>
            <span class="description">
              Общество с ограниченной ответственностью <br />
              "Строительная Компания «Достояние».
            </span>
          </div>
          <div class="card">
            <span class="title">Юридический адрес:</span>
            <span class="description">
              117105, г. Москва ул. Новоданиловская набережная, 6к1
            </span>
          </div>
        </div>
      </vs-col>
      <vs-col :lg="3" :sm="6" :xs="12">
        <div class="law info">
          <div class="card">Телефон:
            <a :href="`tel:${phone}`"
            ><span class="title">{{ phone }}</span></a
            >
          </div>
          <div class="indexes">

            <div class="card">
              <span class="title">ИНН: </span>
              <span class="description">9718159690</span>
            </div>

            <div class="card">
              <span class="title">ОГРН:</span>
              <span class="description">1207700269545</span>
            </div>
          </div>
          <div class="copyright">
            <span id="title">© 2023. Все права защищены.</span>
            <span id="link"><a href="">Политика конфиденциальности.</a> </span>
          </div>
        </div>
      </vs-col>

    </vs-row>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Footer',
  props: {
    phone: String,
  },
}
</script>

<style scoped>
.vs-row {
  justify-content: space-around!important;
}
.info-logo {
  flex-shrink: 0;
}
.logo {
  min-width: 200px;
  margin: 0;
  background: #fff;
  padding: 10px;
  position: relative;
  top: 40px;
}
.info {
  background-color: #17a5e9;
  color: #fff;
  padding: 20px;
  width: 300px;
  min-height: 200px;
}
.container {
  width: 100%;
  background: #000000;
  z-index: -2;
  position: relative;
}
.footer-background {
  position: absolute;
  z-index: -1;
  display: block;
  opacity: 0.3;
  width: 100%;
  height: 100%;
  top: 0;
  -o-object-fit: cover;
  object-fit: cover;
}
a {
  color: inherit;
}
@media screen and (max-width: 900px) {
  .logo {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
  }
  .logo {

    position: static;

  }

  .container {
    padding-top: 56px;
  }

  .info {
    margin-top: 14px;
  }
  .container {
    padding: 20px;
  }
}

@media screen and (min-width: 500px) and (max-width: 900px) {
  .law {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .vs-row {
    flex-direction: column!important;
    align-items: center!important;
  }
  .info{
    min-height: auto;
    width: auto;
  }
  .logo {
    width: 200px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 600px) {

}
@media screen and (max-width: 500px) {
  .info {
    padding: 0 20px;
  }

  .law {
    display: flex;
    justify-content: center;
    padding-left: 15px;
  }

  .contact {
    display: flex;
    padding: 0 20px;
  }

  .contact .card {
    padding-right: 20px;
  }

  .contact .card .title {
    font-size: 16px;
  }

  .contact .card .description {
    font-size: 10px;
  }
  .law {
    flex-direction: column;
  }

  .copyright {
    padding-left: 0;
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 900px) {
  .container {
    padding-top: 74px;
  }

  .contact .card .title {
    font-size: 24px;
  }

  .contact .card .description {
    font-size: 12px;
  }
}

@media screen and (max-width: 360px) {
}

.contact .card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 50px;
}

.contact .card .title {
  font-family: 'Sansation',Arial,sans-serif;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: right;
  margin-bottom: 10px;
}

.contact .card .description {
  font-family: 'Sansation',Arial,sans-serif;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
  color: #17a5e9;
}

#title {
  margin-bottom: 8px;
}

.indexes .card {
  padding-bottom: 10px;
}

.indexes .title {
  font-family: 'Sansation',Arial,sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.indexes .description {
  font-family: 'Sansation',Arial,sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.copyright {
  display: flex;
  flex-direction: column;
}

.copyright span {
  font-family: 'Sansation',Arial,sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}


.info .title {
  font-family: 'Sansation',Arial,sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
}

.info .description {
  font-family: 'Sansation',Arial,sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.info .card {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}
.indexes {
  display: flex;
  gap: 30px;
}


.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding-bottom: 74px;
  background-color: #000;
  /*max-height: 300px;*/
}
</style>
