<!-- eslint-disable max-len -->
<template>
  <vs-table class="vstable" style="max-width: 1160px" :data="data">
    <template #notFound>
      <p></p>
    </template>
    <template #thead>
      <vs-tr>
        <vs-th> Группа </vs-th>
        <vs-th> Название </vs-th>
        <vs-th> Количество </vs-th>
        <vs-th> Единица измерения </vs-th>
        <vs-th> Цена </vs-th>
        <vs-th v-if="money"> Cумма без скидки </vs-th>
        <vs-th> Итоговая сумма </vs-th>
      </vs-tr>
    </template>
    <template #tbody>
      <template v-for="(values, keys) in data">
        <vs-tr v-for="(el, i) in values" :key="el[i]">
          <vs-td v-if="i === 0" :rowspan="values.length">
            {{ keys }}
          </vs-td>
          <vs-td>
            {{ el.name }}
          </vs-td>
          <vs-td>
            {{ el.quantity }}
          </vs-td>
          <vs-td>
            {{ el.unit }}
          </vs-td>
          <vs-td>
            {{ el.price }}
          </vs-td>
          <vs-td v-if="money">
            {{ el.sum }}
          </vs-td>
          <vs-td>
            {{ el.salesum }}
          </vs-td>
        </vs-tr>
        <vs-tr hoverFlat="false" style="background-color: #f4f7f8" :key="keys">
          <vs-td style="text-align: center" colspan="5">Итог</vs-td>
          <vs-td v-if="money">{{ sums[keys].sum }}</vs-td>
          <vs-td>{{ sums[keys].sumsale }}</vs-td>
        </vs-tr>
      </template>
    </template>
    <template #footer>
      <!--<vs-pagination v-model="page" :length="$vs.getLength($vs.getSearch(data, search), max)" /> -->
    </template>
  </vs-table>
</template>
<script>
export default {
  props: {
    products: Object,
    sums: Object,
    money: Number,
  },
  data: () => ({
    search: "",
    page: 1,
    max: 10,
    data: [],
  }),
  created() {
    this.data = this.products;
    // eslint-disable-next-line no-console
    // console.log(this.money);
  },
};
</script>

<style scoped>
</style>
