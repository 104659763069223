<template>
  <div class="container">
    <div class="title">
      <h2>Так же Руководитель отдела дизайна для вас подготовила персональное предложение</h2>
      <span> Посмотрите пожалуйста видео. Нам нужна минута вашего времени </span>
    </div>
    <div class="iframe_wrapper">
      <iframe
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/1INt9K4Lp1k"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
    <div class="flexcont">
      <div class="points">
        <vs-row style="max-width: 1165px" justify="center" align="center">
          <vs-col :lg="6" :sm="6" :xs="12">
            <div class="point">
              <img src="/img/icons/check.svg" alt="check" class="check" />
              <span>Выезд и консультация дизайнера бесплатно;</span>
            </div>
          </vs-col>
          <vs-col :lg="6" :sm="6" :xs="12">
            <div class="point">
              <img src="/img/icons/check.svg" alt="check" class="check" />
              <span>Подбор материалов под ваш бюджет;</span>
            </div>
          </vs-col>
          <vs-col :lg="6" :sm="6" :xs="12">
            <div class="point">
              <img src="/img/icons/check.svg" alt="check" class="check" />
              <span>Скидка на ремонт при заказе дизайна;</span>
            </div>
          </vs-col>
          <vs-col :lg="6" :sm="6" :xs="12">
            <div class="point">
              <img src="/img/icons/check.svg" alt="check" class="check" />
              <span>Экономия бюджета и времени.</span>
            </div>
          </vs-col>
        </vs-row>
      </div>
      <div class="launchDesign">
        <a-button
          ref="launch"
          :loading="loading"
          :disabled="loading"
          class="btnlaunch"
          @click="launch()"
        >
          {{ btnText }}
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Design',
  props: {
    dealID: String,
    rest: String,
  },
  data() {
    return {
      loading: false,
      btnText: 'Связаться с отделом дизайна',
    }
  },
  methods: {
    async launch() {
      this.loading = true
      this.btnText = 'Отправляем...'
      await axios.post(
        `${this.rest}bizproc.workflow.start?TEMPLATE_ID=1033&DOCUMENT_ID[0]=crm&DOCUMENT_ID[1]=CCrmDocumentDeal&DOCUMENT_ID[2]=${this.dealID}`
      )
      this.btnText = 'С вами свяжется ваш личный менеджер'
      this.loading = false
    },
  },
}
</script>

<style scoped>
.flexcont {
  display: flex;
  flex-direction: row;
}
.btnlaunch {
  padding: 2rem;
  height: 70px;
  background: #17a5e9;
  border-radius: 6px;
  border: 1px;
  color: #fff;
  font-family: 'Sansation',Arial,sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: inline-flex;
  align-items: center;
}
@media screen and (max-width: 900px) {

  .flexcont {
    flex-direction: column;
    align-items: center;
  }
  .btnlaunch {
    margin-top: 2rem;
    padding: 1rem;
  }
  .iframe_wrapper {
    height: 260px;
  }
  h2 {
    margin-bottom: 16px;
    font-size: 18px !important;
  }
  span {
    margin-bottom: 40px;
  }
  .point span {
    font-size: 12px !important;
  }
  .point {
    margin-bottom: 0 !important;
    padding: 0 10px;
  }
  .points {
    margin-top: 20px;
  }
  .container {
    padding: 56px 0;
  }
  .title {
    padding: 0 10px;
    text-align: center;
  }
  .title h2 {
    font-size: 18px;
  }
}
@media screen and (min-width: 900px) {
  h2 {
    margin-bottom: 30px;
    font-size: 22px;
  }
  span {
    margin-bottom: 70px;
  }
  .iframe_wrapper {
    height: 660px;
    margin-bottom: 50px;
  }
  .container {
    padding-top: 85px;
    padding-bottom: 68px;
  }
}

img {
  margin-right: 25px;
  max-height: 24px;
}
.point {
  margin-bottom: 21px;
}

.iframe_wrapper {
  width: auto;
}
@media (max-width: 899px) {
  .iframe_wrapper {
    width: 70%
  }

}
@media (min-width: 900px) {
  .iframe_wrapper {
    width: 70%
  }

}

@media (max-width: 600px) {
  .iframe_wrapper {
    width: 100%
  }

}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #000000;
}
h2 {
  font-family: 'Sansation',Arial,sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  max-width: 762px;

  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
}

span {
  font-family: 'Sansation',Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;

  text-align: center;
  color: #ffffff;
}
.title {
  display: flex;
  flex-direction: column;
}
</style>
