import Vue from 'vue';
import Vuesax from 'vuesax';

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

import 'vuesax/dist/vuesax.css';
import 'material-icons/iconfont/material-icons.css';
import 'boxicons/css/boxicons.min.css';

import App from './App.vue';
import VueObserveVisibility from 'vue-observe-visibility';
import './registerServiceWorker';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
Vue.use(Vuesax);
Vue.use(VueObserveVisibility);
Vue.use(Antd);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
