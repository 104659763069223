import axios from 'axios';

export default async function fetchData(userKey, rest) {
  let answer;
  await axios
    .post(
      `${rest}client.offer.get`,
      //'https://tcrm.sknebo.ru/rest/644/foaa7kkcckwjvlaw/client.offer.get',
      {
        key: userKey, // 120885
      },
    )
    .then((response) => {
      answer = response.data;
    });

  return answer;
}
