<template>

  <div class="container">

      <vs-row  style="max-width: 1165px; "  justify="center" align="center" >
        <vs-col  :lg="4 " :sm=" 12 " :xs="12" >
          <div id="title">
            <span id="about">Теперь о цифрах</span>
            <h3>ВАША СМЕТА</h3>
            <span id="description">
              Все корректировки, внесенные вами далее,<br/>  будут также отображены в таблицах ниже
            </span>
          </div>
        </vs-col>
        <vs-col  :lg="4 " :sm=" 12" :xs="12" >
          <div id="image">
            <img ref="dir" src="/img/ruslan.png" alt="check" class="check">
          </div>
        </vs-col>
        <vs-col  :lg="4 " :sm=" 12" :xs="12" >
          <div class="director">
            <span id="name">Ходжаев Руслан Тахирович</span>
            <span id="position">Генеральный директор и <br/> основатель компании Достояние</span>
          </div>
        </vs-col>
      </vs-row>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Director',
}
</script>

<style scoped>
.aboutcont {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.director {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
img {
  max-width: 406px;
  object-fit: contain;
  aspect-ratio: 813/1025;
}
#image {
  height: fit-content;
}
@media screen and (max-width: 900px) {
  #title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 56px;
  }
  .director {
    align-items: center;
  }
  #name {
    text-align: center;
    margin-bottom: 6px !important;
  }
  #position {
    text-align: center;
  }
  .container {
    background: center no-repeat url('/public/img/logo.svg') #f5f7f8;
    background-size: 380px auto;
    padding-bottom: 56px;
    flex-direction: column !important;
    align-items: center;
  }
  #image {
    display: flex;
    justify-content: center;
  }
  .aboutcont {
    align-items: center;
    margin-top: 3rem;
  }
}

@media screen and (min-width: 900px) {
  .director {
    margin-left: 70px;
  }
  .container {
    background: center no-repeat url('/public/img/logo.svg') #f5f7f8;
    background-size: 500px auto;
  }

  h3 {
    font-size: 36px;
  }
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
}
h3 {
  max-width: 263px;
  font-family: 'Sansation',Arial,sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 150%;
  text-transform: uppercase;
  color: #000000;
  margin: 10px 0 15px 0;
}
#about {
  max-width: 143px;
  font-family: 'Sansation',Arial,sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
}
#description {
  max-width: 348px;
  font-family: 'Sansation',Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
}
#name {
  max-width: 300px;

  font-family: 'Sansation',Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 150%;
  color: #000000;
  margin-bottom: 18px;
}
#position {
  max-width: 300px;
  font-family: 'Sansation',Arial,sans-serif;
  font-family: 'Sansation',Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
}
</style>
