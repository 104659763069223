<template>
  <div class='wrapper' style="--n: 1">
  <article v-if='sumFull'   style="--i: 0;--backgroundCard: #17a5e9; --grad: #fff, #fff" >
    <header>
      <h3 data-name="Стоимость работ"></h3>
      <span>{{sumFull}} </span><span class='sum-description' style='color: rgba(0, 0, 0, 0.65);'>руб.</span>

    </header>

  </article>
  <article v-if='sumMinus' style="--i: 1; --backgroundCard: #fff; color: #fff;  --grad: #17a5e9, #17a5e9">
    <header>
      <h3 style='color: #fff;' data-name="Скидка"></h3>
      <span style='color: #fff;'>-{{sumMinus}} </span><span class='sum-description' style='color: #fff;'>руб.</span>

    </header>

  </article>
  <article v-if='sumDiscount' style="--i: 2;--backgroundCard: #17a5e9;--grad: #fff, #fff">
    <header>
      <h3 data-name="Итог по сумме"></h3>
      <span>{{sumDiscount}} </span><span class='sum-description' style='color: rgba(0, 0, 0, 0.65);'>руб.</span>

    </header>

  </article>
  </div>
</template>

<script>
export default {
  name: 'BlockPrices',
  props : {
    sumFull: String,
    sumMinus: String,
    sumDiscount: String

  },
  mounted() {

  }
};

</script>

<style scoped>
.sum-description {
  position: absolute;
  font-size: 12px;
  right: 13px;
  bottom: 25px;
}

.wrapper, .wrapper :not(script):not(style), ::before {
  display: grid;
}

.wrapper {

  place-content: center;


  font: 500 1.25em/1.25 'Sansation', Arial, sans-serif;


  text-align: center;

}

article {
  --m: calc(.5*(var(--n) - 1));
  --abs: Max(calc(var(--i) - var(--m)), calc(var(--m) - var(--i)));
  --val: calc(var(--abs)*(1 + .5*var(--i))/var(--n));
  --dt0: calc(var(--val)*1s);
  --dt1: calc((var(--val) + 1)*1s);
  --dt2: calc((var(--val) + 1.2)*1s);
  --dt3: calc((var(--val) + 1.3)*1s);
  --dt4: calc((var(--val) + 1.4)*1s);
  --dt5: calc((var(--val) + 1.5)*1s);
  overflow: hidden;

  backface-visibility: hidden;
  transform: perspective(25em);
  border-radius: 10px!important;
  background: var(--backgroundCard) content-box;
  box-shadow: 0px 20px 50px rgb(0 0 0 / 10%);
  animation: card 0.5s ease-out var(--dt0) backwards, cost 0.6s var(--dt4) backwards;
  width: 250px;
  height: 150px;
}
h3 {
  color: #000;
  font-size: 14px;
}
article ::before, article ::after, article button {
  --j: 1;
  --sgn-j: calc(2*var(--j) - 1);
  --not-j: calc(1 - var(--j));
  --mask:
    linear-gradient(calc(var(--sgn-j)*90deg),
    red 33.333%, transparent 66.667%) calc(var(--not-j)*100%)/ 300%;
}

@keyframes card {
  0% {
    transform: perspective(25em) rotatey(0.5turn);
  }
}
header {
  position: relative;
  background: none;
  border-radius: 10px;

  padding: 2rem 0.5em 1rem;
  transform-origin: 100% 100%;
  background: linear-gradient(var(--grad));
  color: rgb(0, 99, 175);

  animation: head 0.6s ease-out var(--dt1) backwards;
}
span {
  place-self: center;
  place-content: center;
  font-weight: 700;
  width: 4rem;
  height: 4rem;
  font-size: 28px;
  white-space: nowrap;
  animation: ring 0.5s ease-out var(--dt3) backwards;
  position: relative;
  right: 10px;
}
/*span::after {*/
/*  height: 4px;*/
/*  background: currentcolor;*/
/*  clip-path: inset(0 round 4px);*/
/*  animation: line 1s ease-out var(--dt2) backwards;*/
/*  content: "";*/
/*}*/

@keyframes head {
  0% {
    transform: translate(-100%) skewx(35deg);
  }
}
@keyframes ring {
  0% {
    transform: scale(0);
    opacity: 0;
  }
}
@keyframes line {
  0% {
    clip-path: inset(0 50% round 4px);
  }
}
h3::before, h3::after {
  place-self: center;
  -webkit-mask: var(--mask);
  mask: var(--mask);
  animation: text 0.4s ease-out var(--dt5) backwards;
}
h3::before {
  --j: 0;
  animation-name: text, move;
  content: attr(data-name);
}


@keyframes move {
  0% {
    transform: translate(50%);
  }
}
section {
  margin-bottom: -1rem;
  border-bottom: solid 1rem transparent;
  background-color: inherit;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1rem), 50% 100%, 0 calc(100% - 1rem));
}

button {
  place-content: center;
  line-height: 3;
  -webkit-mask: var(--mask);
  mask: var(--mask);
  cursor: pointer;
  animation: text 1s ease-out var(--dt2) backwards;
}

@keyframes text {
  0% {
    -webkit-mask-position: calc(var(--j)*100%);
    mask-position: calc(var(--j)*100%);
  }
}
</style>
