<template>
  <vs-row style="margin: 0 auto" justify="center" align="center" v-if="!loading">
    <HeaderWaves :name="data.client_name" />

    <Present v-if="data.money.sale" :sale="data.money.sale" />

    <Prices v-if="data.money.sale" :sale="data.money.sale" :money="data.money" />
    <Workers :measurer="data.workers.measurer" :prorab="data.workers.prorab" />

    <Director />
    <TablesBlock
      v-if="data.products"
      :tables="data.products"
      :sums="data.sum_per_area"
      :perRoom="data.sum_per_room"
      :salePerRoom="data.sale_sum_per_room"
    />
    <ContactManager
      :uri="data.pdf"
      :manager-contact="data.workers.measurer.phone.replace('+', '')"
      :dealID="userKey"
      :client="data.client_name"
      :manager-name="data.workers.measurer.name"
    />
    <Design :id="userKey" :dealID="userKey" :rest="rest" />
    <about-work></about-work>
    <!-- <HotLine  />-->
    <!--     <Footer :phone="data.company_phone || '74951045553'" />-->
    <FooterNew :phone="data.company_phone || '74951045553'"></FooterNew>
  </vs-row>
  <div class="logopic" v-else>
    <img alt="" :src="znak" />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Present from '@/components/Present.vue'
import Prices from '@/components/Prices/Prices.vue'
import Workers from '@/components/Workers/Workers.vue'
import Design from '@/components/Design.vue'
import Director from '@/components/Director.vue'
import ContactManager from '@/components/ContactManager.vue'
import fetchData from '@/api/fetchData'
import Slider from '@/components/Slider/Slider.vue'
import HotLine from '@/components/HotLine.vue'
import Footer from '@/components/Footer.vue'
import TablesBlock from '@/components/TablesBlock.vue'
import znak from '../assets/logo.png.svg'
import AboutWork from '@/components/aboutWork'
import PricesNew from '@/components/PricesNew'
import HeaderWaves from '@/components/HeaderWaves'
import FooterNew from '@/components/FooterNew'

export default {
  name: 'MainView',
  components: {
    FooterNew,
    HeaderWaves,
    PricesNew,
    AboutWork,
    Present,
    Header,
    Prices,
    Workers,
    Design,
    Director,
    ContactManager,
    Slider,
    HotLine,
    Footer,
    TablesBlock,
  },
  props: {},
  data() {
    return {
      data: null,
      userKey: null,
      znak,
      loading: false,
      rest: 'https://crm.sknebo.ru/rest/389/cr66zag83xseaby7/',
    }
  },
  async created() {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      roundingIncrement: 1,
    })
    this.loading = true
    this.data = (await fetchData(this.$route.params.userKey, this.rest)).result
    if (this.data.error) {
      this.$router.push('/')
    }

    Object.keys(this.data.money).forEach((key) => {
      if (key === 'sale') return
      this.data.money[key] = formatter.format(parseFloat(this.data.money[key]))
    })
    Object.keys(this.data.products).forEach((key) => {
      Object.keys(this.data.products[key]).forEach((sub) => {
        this.data.products[key][sub].forEach((elem) => {
          // eslint-disable-next-line no-param-reassign
          elem.price = formatter.format(elem.price)
          // eslint-disable-next-line no-param-reassign
          elem.sum = formatter.format(elem.sum)
          // eslint-disable-next-line no-param-reassign
          elem.salesum = formatter.format(elem.salesum)
        })
      })
    })
    Object.keys(this.data.sum_per_area).forEach((key) => {
      Object.keys(this.data.sum_per_area[key]).forEach((sub) => {
        if (sub === 'sum' || sub === 'sumsale') return
        this.data.sum_per_area[key][sub].sum = formatter.format(
          this.data.sum_per_area[key][sub].sum
        )
        this.data.sum_per_area[key][sub].sumsale = formatter.format(
          this.data.sum_per_area[key][sub].sumsale
        )
      })
    })
    Object.keys(this.data.sum_per_room).forEach((key) => {
      this.data.sum_per_room[key].sum = formatter.format(this.data.sum_per_room[key].sum)
    })
    Object.keys(this.data.sale_sum_per_room).forEach((key) => {
      this.data.sale_sum_per_room[key].sum = formatter.format(this.data.sale_sum_per_room[key].sum)
    })

    this.loading = false
    this.userKey = this.data.dealID
  },
}
</script>

<style>
@font-face {
  font-family: 'Sansation';
  font-style: normal;
  font-weight: 400;
  src: local('Sansation'), url('../fonts/SansationRegular.ttf') format('ttf');
}
@font-face {
  font-family: 'Sansation';
  font-style: normal;
  font-weight: 500;
  src: local('Sansation'), url('../fonts/SansationBold.ttf') format('ttf');
}
body {
  font-family: 'Sansation', Arial, sans-serif;
}
.owl-carousel .owl-stage-outer {
  max-width: min(96vw, 52rem);
}
/* @media screen and (max-width: 400px){
.owl-carousel .owl-stage-outer {
    max-width: 400px !important;
}
}
@media screen and (max-width:490px) {
  .owl-carousel .owl-stage-outer{
  max-width: 465px !important;
 }
}
@media screen and (max-width:800px) {
  .owl-carousel .owl-stage-outer{
  max-width: 700px !important;
 }
} */
.logopic {
  background-color: #f2f3f3;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logopic img {
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
  animation: shimmer 2s infinite;
  max-width: 100vw;
}
@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}
.owl-stage-outer {
  width: fit-content;
}
.owl-carousel {
  width: fit-content;
}
body {
  overflow-x: hidden !important;
}
/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f100;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
