<template>
  <span class='title-name'>
    <span v-for="(letter, index) in currentWord" :key="index">{{ letter }}</span>
  </span>
</template>

<script>
export default {
  name: 'HeaderTitleArray',
  props: {
    words: {
      type: Array,
      required: true,
    },
    delay: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      currentWordIndex: 0,
      currentLetterIndex: 0,
      currentWord: '',
    };
  },
  mounted() {
    this.startTyping();
  },
  methods: {
    startTyping() {

        setInterval(() => {

          if (this.currentLetterIndex === this.currentWord.length) {
            this.currentLetterIndex++;
            this.currentWord = this.words[this.currentWordIndex].substring(0, this.currentLetterIndex);

          } else {

                this.currentWordIndex = (this.currentWordIndex + 1) % this.words.length;
                this.currentLetterIndex = 0;
                this.currentWord = '';






          }
        }, this.delay);


    },
  },
};
</script>
