<template>
  <div class="container">
    <h2>Этапы работ</h2>
    <carousel id="slider" v-model="elements" :nav="false" :autoWidth="true" :dots="false">
      <SliderElement v-for="el in this.elements" :key="el.id"
                     :el="el" :active="activeElement"
      />
    </carousel>
  </div>
</template>

<script>

import SliderElement from '@/components/Slider/SliderElement.vue';
import carousel from 'vue-owl-carousel';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Slider',
  props: {
    date: String,
  },
  components: {
    SliderElement,
    carousel,
  },
  data() {
    return {
      activeElement: 1,
      elements: [],
    };
  },
  created() {
    this.elements.push(
      {
        id: 1,
        text: 'Встреча с инженером-сметчиком',
        photo: '/img/slider_photo1.png',
      },
      {
        id: 2,
        text: 'Встреча с прорабом, подписание договора',
        photo: '/img/slider_photo2.png',
      },
      {
        id: 3,
        text: 'Ремонт с обговоренными точками контроля',
        photo: '/img/slider_photo3.png',
      },
      {
        id: 4,
        text: 'Сдача объекта',
        photo: '/img/slider_photo4.png',
      },
      {
        id: 5,
        text: 'Довольный и счастливый заказчик',
        photo: '/img/slider_photo5.png',
      },
    );
  },
  methods: {
    calculateNextDate: (date, days) => {
      const dateSplit = date.split('.');
      const inter = dateSplit[0];
      // eslint-disable-next-line
      dateSplit[0] = dateSplit[1];
      dateSplit[1] = inter;
      const newDate = new Date(dateSplit.join());
      newDate.setDate(newDate.getDate() + days);
      return newDate.toLocaleDateString();
    },
  },

};
</script>

<style scoped>
@media screen and (max-width: 900px) {
  .container {
    padding: 56px 0;
  }

  h2 {
    padding-bottom: 38px;
  }
}

@media screen and (min-width: 250px) and (max-width: 300px){
  #slider{
    max-width: 250px;
  }

}

@media screen and (min-width: 1000px) and (max-width: 1100px){
  #slider{
    max-width: 1000px;
  }

}

@media screen and (min-width: 900px) and (max-width: 1000px){
  #slider{
    max-width: 900px;
  }

}

@media screen and (min-width: 900px) {
  .container {
    padding-top: 76px;
    padding-bottom: 90px;
  }

  h2 {
    padding-bottom: 58px;
  }
}

h2 {
  font-size: 22px;
}

.container {
  background: #F5F7F8;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
</style>
