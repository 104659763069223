<template>
  <div class="container">
    <div class="wrp">
      <h3>ТЕЛЕФОН ГОРЯЧЕЙ ЛИНИИ</h3>
      <span
        ><a :href="`tel:${phone}`">{{ phone }}</a></span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'HotLine',
  props: {
    phone: String,
  },
}
</script>

<style scoped>
a {
  color: inherit;
}
@media screen and (max-width: 900px) {
  h3 {
    margin-top: 20px;
  }
  span {
    margin-bottom: 20px;
    font-size: 22px;
  }
}
@media screen and (min-width: 900px) {
  h3 {
  }
  span {
    font-size: 44px;
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 400px;
  background: top / cover no-repeat url('/public/img/sec7.jpg') black;
}
h3 {
  max-width: 762px;
  font-family: 'Sansation',Arial,sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 150%;
  text-align: center;
  text-transform: uppercase;
  color: #17a5e9;
}
.wrp {
  text-align: center;
}
span {
  max-width: 762px;

  font-family: 'Sansation',Arial,sans-serif;
  font-style: normal;
  font-weight: 900;
  line-height: 150%;

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
}
</style>
