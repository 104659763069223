<template>
  <div class="header">
    <div class='parallaxx'></div>
    <!--Content before waves-->
    <div class="inner-header flex">

      <div class='header-wrapper' style='z-index: 3;'>
        <img width='146' height='50' src='/img/dostoynya.svg' alt='Логотип.'>
        <a class="header-phone" href='tel:74951522626'>+7 (495) 152-26-26</a>
      </div>




        <h1 class='header-title'>
          <!--ИНДИВИДУАЛЬНОЕ РЕШЕНИЕ ПО РЕМОНТНЫМ<br> РАБОТАМ ДЛЯ ВАС,-->
          <HeaderTitleNew :messages="[`ИНДИВИДУАЛЬНОЕ РЕШЕНИЕ ПО РЕМОНТНЫМ РАБОТАМ ДЛЯ ВАС, ${name}`,`Экономьте время и деньги с помощью наших услуг`,`Получите точную смету от профессионалов для вашего проекта, ${name}`]"></HeaderTitleNew>
        </h1>



    </div>

    <!--Waves Container-->
    <div>
      <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
           viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
        <defs>
          <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
        </defs>
        <g class="parallax">
          <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
          <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
          <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
          <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
        </g>
      </svg>
    </div>


  </div>

</template>

<script>
import HeaderTitleNew from '@/components/HeaderTitleNew'
import Sky from '@/components/Sky'
import HeaderTitleArray from '@/components/HeaderTitleArray';
export default {
  name: 'HeaderWaves',
  components: { HeaderTitleArray, Sky, HeaderTitleNew },
  props: {
    name: String,
  },
data(){
    return{
      arrayTitle: ['Экономьте время и деньги с помощью наших сметных услуг']
    }
},
}
</script>

<style scoped>


.parallaxx {
  position: absolute;
  width: 50%;
  height: 100%;


  opacity: 0.25;
  /* Full height */
  background-image: url('../assets/icon-dosto-grey.svg');

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-size: 30%;
  background-position: 20%;
  background-repeat: no-repeat;
}
@media only screen and (max-device-width: 1366px) {
  .parallaxx {
    background-attachment: scroll;
  }
  .parallaxx {
    background-size: 80%;

  }
}
@media only screen and (max-device-width: 900px){
  .parallaxx {
    background-size: 80%;
    width: 85%;
  }
}

  .header-phone {
  color: #fff;
  font-size: 20px;
}
.header-name {
  color: #0063af;
  text-transform: uppercase;
}
.header-title {
  color: #ffffff;
  font-weight:300;
  letter-spacing: 2px;
  font-size: 28px;
  z-index: 2;
  max-width: 840px;
  padding: 0 10px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  white-space: pre;
}

@media(max-width: 1000px) {
  .header-title {
    min-width: 340px;
    font-size: 18px;
  }

}



@keyframes anim {
  0% {
    text-shadow: 0 0 50px #fff;
    letter-spacing: 80px;
    opacity: 0;
    -moz-transform: rotateY(-90deg);
  }
  50% {
    text-shadow: 0 0 1px #fff;
    opacity: 0.8;
    -moz-transform: rotateY(0deg);
  }
  75% {
    text-shadow: 0 0 1px #fff;
    opacity: 0.8;
    -moz-transform: rotateY(0deg) translateZ(60px);
  }
  100% {
    text-shadow: 0 0 1px #fff;
    opacity: 0.8;
    letter-spacing: 3px;
    -moz-transform: rotateY(0deg) translateZ(100px);
  }

}
@media(max-width: 650px) {

  .header-phone {
    color: #fff;
    font-size: 18px;
  }
  .header-title {
    min-width: 100%;
    font-size: 18px;
    letter-spacing: 0px;
  }
  @keyframes anim {
    0% {
      text-shadow: 0 0 50px #fff;
      letter-spacing: 80px;
      opacity: 0;
      -moz-transform: rotateY(-90deg);
    }
    50% {
      text-shadow: 0 0 1px #fff;
      opacity: 0.8;
      -moz-transform: rotateY(0deg);
    }
    75% {
      text-shadow: 0 0 1px #fff;
      opacity: 0.8;
      -moz-transform: rotateY(0deg) translateZ(60px);
    }
    100% {
      text-shadow: 0 0 1px #fff;
      opacity: 0.8;
      letter-spacing: 1px;
      -moz-transform: rotateY(0deg) translateZ(100px);
    }

  }
}
p {

  letter-spacing: 1px;
  font-size:14px;
  color: #333333;
}
.header-wrapper {
  display: flex;
  width: 50%;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 50px;
}

.header {
  position:relative;
  text-align:center;
  background: linear-gradient(60deg,#17a5e9  0%, #a4d5f1 100%);
  color:white;
  width: 100%;
  height: 100vh;
}
@media (max-width: 1024px) {
  .header {

    height: 80vh;
  }
}
@media (max-width: 600px) {
  .header {

    height: 60vh;
  }
}
.logo {
  width:50px;
  fill:white;
  padding-right:15px;
  display:inline-block;
  vertical-align: middle;
}

.inner-header {
  height:65vh;
  width:100%;
  margin: 0;
  padding: 0;
}

.flex { /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.waves {
  position:absolute;
  width: 100%;
  height:15vh;
  margin-bottom:-7px; /*Fix for safari gap*/
  min-height:100px;
  max-height:150px;
  bottom: 0;
  left: 0;
}

.content {
  position:relative;
  height:20vh;
  text-align:center;
  background-color: white;
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px,0,0);
  }
  100% {
    transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height:40px;
    min-height:40px;
  }
  .content {
    height:30vh;
  }
  h1 {
    font-size:24px;
  }
}
@media(max-width: 1000px) {
  .header-wrapper {
    width: 100%;
    justify-content: space-around;
  }
}
</style>
