<template >
  <div class="card card_mobile listing" >

    <img :src="this.photo" alt="photo" class="photo" >
    <div class="info info_left" >
      <span class="name" >{{this.name}}</span>
      <span class="description">{{this.desc}}</span>
    </div>

  </div>

</template>

<script>

export default {
  name: 'WorkerCard',
  props: {
    desc: String,
    name: String,
    photo: String,

  },
};
</script>


<style scoped>
@media (max-width: 620px) {
  .card_wrapper {
    position: relative;
    left: 50px;
  }
  .card_image {
    left: -90px;

  }

}

.card {
  position: relative;
}

@media screen and (max-width: 900px) {
  .card {
    flex-direction: column;
  }


  .info{
    align-items: center;
  }
}
@media screen and (min-width: 900px) {




}
.card{
  display: grid;
  align-items: center;
  justify-items: center;

}
.name{
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  max-width: 240px;
  text-align: center;

}
.description{
  max-width: 240px;
  min-height: 87px;
  display: flex;
  justify-content: center;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
  margin-top: 10px;
}
 img{

   object-fit: cover;
   object-position: top;
 }
.listing {

  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
  cursor: pointer;
  text-decoration: none;
  width: 300px
}
.listing img {
  width: 300px;
  height: 230px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  justify-content: center;
  width: 300px;
  min-height: 180px;
  width: 100%;
  padding: 13px 0;
}

.listing img + * {
  background: #fff;
  color: #000;

}
@media(max-width: 900px){

}
.listing .name {
  color: black;
  background: white;

  position: relative;
  margin-bottom: 20px;
  font-size: 18px;
  width: 155px;
  text-align: center;
  min-height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.listing .name::after {
  position: absolute;
  content: '';
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: 2px;
  background-color: #17a5e9;
  bottom: -14px;
}
.listing .description {
  position: relative;

  font-size: 1.04rem;
  line-height: 1.3;

  color: #000;
}
</style>
